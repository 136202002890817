<script lang="ts" setup>
import NavbarComponent from "@/components/NavbarComponent.vue";
import FootComponent from "@/components/FootComponent.vue";
import SectionComponent from "@/components/SectionComponent.vue";
import BiographyComponent from "@/components/BiographyComponent.vue";
import { useRoute } from "vue-router";

const route = useRoute();
const locale = (route.query.locale as "en" | "it") ?? (route.query.lang as "en" | "it") ?? "en";
</script>

<template>
  <NavbarComponent />
  <SectionComponent id="about" class="min-h-screen">
    <BiographyComponent :locale="locale" />
  </SectionComponent>
  <FootComponent />
</template>
