import * as Contentful from "contentful";

const contentful = Contentful.createClient({
  space: import.meta.env.VITE_CONTENTFUL_SPACE_ID,
  environment: "master", // defaults to 'master' if not set
  accessToken: import.meta.env.VITE_CONTENTFUL_ACCESS_TOKEN,
});

export default contentful;

export * from './types/biography';
export * from './types/contact';
export * from './types/external-link';
export * from './types/news';
export * from './types/footer';
export * from './types/social-link';
