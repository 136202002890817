<!-- BiographyComponent.vue -->
<script lang="ts" setup>
import { ref, onMounted } from "vue";
import SectionComponent from "@/components/SectionComponent.vue";
import ReviewsComponent from "@/components/ReviewsComponent.vue";
import { useContentful } from "@/composables/useContentful";
import type { Biography } from "@/models/contentful";
import type { Asset } from "contentful";

const props = defineProps<{
  locale?: "en" | "it";
}>();

const bio = ref<Biography>();
const loading = ref(true);
const error = ref<Error>();

onMounted(async () => {
  try {
    bio.value = await useContentful().bio.full(props.locale);
  } catch (e) {
    error.value = e as Error;
  } finally {
    loading.value = false;
  }
});

const getImageUrl = (image: Asset): string => {
  return `https:${image.fields.file?.url ?? ''}`;
};

const getImageTitle = (image: Asset): string => {
  return typeof image.fields.title === 'string' ? image.fields.title : '';
};

const getImageDetails = (image: Asset): string => {
  const file = image.fields.file;
  if (!file) return '';

  const details = file.details;
  if (!details) return '';

  const size = 'size' in details && typeof details.size === 'number' ?
    details.size < 1024 * 1024 ?
      `${(details.size / 1024).toFixed(1)} KB` :
      `${(details.size / 1024 / 1024).toFixed(1)} MB` :
    '';

  const resolution = 'image' in details && details.image ?
    `${details.image.width}×${details.image.height}` :
    '';

  const mime = file.contentType || '';

  return [size, resolution, mime].filter(Boolean).join(' • ');
};

const openImage = (image: Asset): void => {
  window.open(getImageUrl(image), '_blank');
};
</script>

<template>
  <SectionComponent id="about" title="About" class="font-source-code-pro mx-auto max-w-lg px-0! py-10!">
    <div v-if="loading" class="text-center">
      <div class="animate-pulse">Loading biography...</div>
    </div>

    <div v-else-if="error" class="text-center text-red-500">
      {{ error.message }}
    </div>

    <div v-else-if="bio" class="text-justify" v-html="bio.renderHtml()"></div>
  </SectionComponent>

  <SectionComponent id="gallery" title="Gallery" v-if="bio?.imageGallery && bio.imageGallery.length > 0"
    class="font-source-code-pro mx-auto w-full px-0! py-10!">
    <h2 class="text-2xl font-bold mb-4">Image Gallery</h2>
    <p>Click on the images to view and download them in full resolution.</p>
    <div class="mt-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      <figure v-for="image in bio.imageGallery" :key="image.sys.id" class="relative">
        <img :src="getImageUrl(image)" :alt="getImageTitle(image)"
          class="w-full h-64 object-cover rounded-lg cursor-pointer hover:opacity-90 transition-opacity"
          @click="openImage(image)" />
        <figcaption v-if="image.fields.title || image.fields.file" class="text-sm text-base-700 mt-2 text-justify">
          <div v-if="image.fields.title" class="font-bold">{{ getImageTitle(image) }}</div>
          <div v-if="image.fields.file" class="text-xs opacity-75">{{ getImageDetails(image) }}</div>
        </figcaption>
      </figure>
    </div>
  </SectionComponent>

  <SectionComponent id="reviews" title="Reviews" class="font-source-code-pro mx-auto max-w-xl px-0! py-0!">
    <ReviewsComponent :locale="locale" />
  </SectionComponent>
</template>
