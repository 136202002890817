<script lang="ts" setup>
import YoutubeVideo from "@/components/YoutubeVideo.vue";
import News, { Footer, Biography } from "@/models/contentful";
import { useSafeEmail } from "@/composables/useSafeEmail";
import { useContentful } from "@/composables/useContentful";

const props = defineProps<{
  news: News;
  locale: "en" | "it";
  full?: boolean;
}>();

let footer: Footer | undefined = undefined;
let bio: Biography | undefined = undefined;
if (props.full) {
  footer = await useContentful().footer(props.locale);
  bio = await useContentful().bio.short(props.locale);
}
</script>

<template>
  <article>
    <header class="uppercase mx-auto max-w-lg">
      <time datetime="{{ news.date.format('YYYY-MM-DD') }}">
        <RouterLink :to="{ name: 'newsPost', params: news.routeParams }">
          <span class="text-base-600 text-sm font-semibold">{{ news.date.format("MMMM DD, YYYY") }}</span>
        </RouterLink>
      </time>
      <h1 class="text-5xl font-thin p-2">
        <RouterLink :to="{ name: 'newsPost', params: news.routeParams }">
          <span class="text-white">{{ news.headline }}</span>
        </RouterLink>
      </h1>
    </header>
    <section class="font-source-code-pro mx-auto max-w-lg py-10!">
      <div class="text-justify" v-html="news.renderBody()"></div>
    </section>
    <div v-if="full">
      <section v-for="(link, key) in news.links" :key="key" class="py-10!">
        <div v-if="String(link.fields.type) === 'Youtube'" class="mx-auto max-w-3xl">
          <YoutubeVideo :url="String(link.fields.url)" :title="String(link.fields.title)" />
        </div>
        <p class="font-source-code-pro mx-auto max-w-lg text-left">
          {{ link.fields.title }}:
          <a :href="String(link.fields.url)" target="_blank">{{ link.fields.url }}</a>
        </p>
      </section>
      <figure v-if="news.featuredImageUrl" class="mx-auto max-w-3xl">
        <img :src="news.featuredImageUrl" :alt="news.featuredImageAlt" class="w-full" />
        <figcaption class="text-center text-sm text-base-700 py-2">
          {{ news.featuredImage.fields.description ?? news.featuredImage.fields.title ?? news.featuredImageAlt }}
        </figcaption>
      </figure>
      <hr class="border-1! border-base-800! mt-20" />
      <section v-if="bio" class="font-source-code-pro mx-auto max-w-lg py-10!">
        <div class="text-justify" v-html="bio?.renderHtml()"></div>
      </section>
      <footer v-if="footer" class="font-source-code-pro mx-auto max-w-md text-left">
        <h4 class="text-2xl font-thin">Contacts</h4>
        <ul class="list-inside">
          <li v-for="(contact, key) in footer.contacts" :key="key">
            <span>{{ contact.fields.key }}:
              <a :href="`mailto:` + useSafeEmail(String(contact.fields.value)).value" target="_blank">{{
                useSafeEmail(String(contact.fields.value))
                }}</a>
            </span>
          </li>
        </ul>
        <h4 class="text-2xl font-thin py-10">Follow Disease Illusion</h4>
        <ul class="list-inside">
          <li v-for="(link, key) in footer.socialLinks" :key="key">
            <span>{{ link.fields.platform }}:
              <a :href="String(link.fields.url)" target="_blank">{{ link.fields.display ?? link.fields.url }}</a></span>
          </li>
        </ul>
      </footer>
    </div>
  </article>
</template>

<style lang="css" scoped></style>
