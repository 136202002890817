<script lang="ts" setup>
import Event, { type EventArtist, PerformanceType } from "@/models/event";

defineProps<{
  event: Event;
}>();

const commaList = (artists: EventArtist[], andMore: boolean = false): string => {
  const bandName = (artist: EventArtist): string => `<span class='text-base-400'>${artist.name}</span>`;
  const items = artists.map(artist => artist.name);
  if (andMore) {
    items.push("more");
  }
  if (items.length === 0) {
    return "";
  }
  if (items.length === 1) {
    return bandName(artists[0]);
  }
  if (items.length === 2) {
    return `${bandName(artists[0])} and ${bandName(artists[1])}`;
  }
  return items
    .map((item, index) => {
      const artist = { id: "", name: item, performanceType: PerformanceType.MAIN } as EventArtist;
      if (index === items.length - 1) {
        return `and ${bandName(artist!)}`;
      }
      return `${bandName(artist!)}`;
    })
    .join(", ");
};

const buildArtistsNamesLine = (artists: EventArtist[], limit = 3): string => {
  const diseaseIllusion = artists.find(artist => artist.name === "Disease Illusion")!;
  const filteredArtists = artists.filter(artist => artist.name !== "Disease Illusion");
  const mainArtists = filteredArtists.filter(artist => artist.performanceType === PerformanceType.MAIN);
  const supportArtists = filteredArtists.filter(artist => artist.performanceType === PerformanceType.SUPPORT);
  let availableSlots = limit;
  let names: string = "";
  if (diseaseIllusion.performanceType === PerformanceType.MAIN) {
    if (mainArtists.length > 0) {
      names = `with ${commaList(mainArtists)}`;
      availableSlots -= mainArtists.length;
    }
    let limitedSupportArtists = supportArtists.slice(0, availableSlots);
    if (limitedSupportArtists.length > 0) {
      names += ` supported by ${commaList(limitedSupportArtists, supportArtists.length > availableSlots)}`;
    }
  }

  if (diseaseIllusion.performanceType === PerformanceType.SUPPORT) {
    if (mainArtists.length > 0) {
      names = `supporting ${commaList(mainArtists)}`;
      availableSlots -= mainArtists.length;
    }
    let limitedSupportArtists = supportArtists.slice(0, availableSlots);
    if (limitedSupportArtists.length > 0) {
      names += ` with ${commaList(limitedSupportArtists, supportArtists.length > availableSlots)}`;
    }
  }

  return names;
};

const eventClass = (event: Event): string => {
  const isPast = event.isPast();
  const isCanceled = event.canceled;
  if (isCanceled) {
    return "bg-red-900/10 hover:bg-red-900/20";
  }
  if (isPast) {
    return "bg-base-950/80 hover:bg-base-900";
  }
  return "bg-base-900/80 hover:bg-base-800";
};
</script>

<template>
  <a
    :class="eventClass(event)"
    :href="`/shows/${event.getSlug()}`"
    class="w-full flex flex-wrap flex-row justify-center text-left my-4 p-5 mx-auto rounded-lg text-white"
  >
    <div class="grow items-left">
      <div class="pb-2">
        <div v-if="!event.isPast()" class="uppercase text-sm font-semibold">
          <span class="text-base-400">{{ event.getStartDate() }}</span>
          <span class="p-2 text-base-500">•</span>
          <span v-if="!event.canceled" class="text-xs text-base-400">{{ event.getStartTime() }}</span>
          <span v-if="event.canceled" class="text-xs text-red-500">Canceled</span>
        </div>
        <div v-if="event.isPast()" class="uppercase text-sm">
          <span v-if="!event.canceled" class="text-xs text-base-400">Past event</span>
          <span v-if="event.canceled" class="text-xs text-red-500">Canceled event</span>
          <span class="p-2 text-base-500">•</span>
          <span class="text-base-400">{{ event.getStartDate() }}</span>
        </div>
      </div>
      <div class="pb-2">
        <div class="text-xl text-base-300 font-semibold">{{ event.getCity() }}, {{ event.getCountry() }}</div>
        <div class="text-md text-base-300 font-normal">
          {{ event.isFestival() ? event.getName() : event.getVenue() }}
        </div>
      </div>
      <div v-if="event.hasSupport()" class="text-xs text-base-400 pb-5">
        <span class="hidden md:block" v-html="buildArtistsNamesLine(event.artists, 4)"></span>
      </div>
    </div>
    <div class="content-center text-center w-50">
      <a
        v-if="event.hasLinks() && ((!event.isPast() && !event.canceled) || event.isPast())"
        :href="event.getRelevantLink()"
        target="_blank"
      >
        <button
          class="w-full uppercase text-sm bg-base-100 text-black font-semibold py-2 px-5 rounded-sm hover:bg-base-900 hover:text-white focus:outline-hidden focus:ring-2 focus:bg-base-300 focus:ring-opacity-50 transition duration-300 hover:cursor-pointer"
        >
          {{ event.isPast() ? "View" : "Tickets" }}
        </button>
      </a>
    </div>
  </a>
</template>

<style scoped></style>
