<script lang="ts" setup>
import NavbarComponent from "@/components/NavbarComponent.vue";
import SectionComponent from "@/components/SectionComponent.vue";
import FootComponent from "@/components/FootComponent.vue";
import ReleaseComponent from "@/components/ReleaseComponent.vue";
import { useRoute } from "vue-router";
import HeadComponent from "@/components/HeadComponent.vue";
import { useReleases } from "@/composables/useDB";

const route = useRoute();
const releaseId = route.params.release as string;
const release = useReleases().findBy("shortSlug", releaseId);
</script>

<template>
  <NavbarComponent />
  <HeadComponent compact />
  <!-- Available colors: bg-plasticocean-500, bg-afterthestorm-500, bg-backworld-500, bg-realitybehindtheillusionoflife-500 -->
  <SectionComponent id="album" :class="{ [`bg-${release.shortSlug}-500`]: true }" class="min-h-screen">
    <ReleaseComponent :release="release" />
  </SectionComponent>
  <FootComponent />
</template>

<style scoped></style>
