<!-- ReviewsComponent.vue -->
<script lang="ts" setup>
import { ref, onMounted } from "vue";
import { useContentful } from "@/composables/useContentful";
import type { Review } from "@/models/contentful";
import LoadingComponent from './LoadingComponent.vue'
import ErrorComponent from './ErrorComponent.vue'

const reviews = ref<Record<string, Review[]>>({}); // Initialize as an empty object
const loading = ref(true);
const error = ref<Error>();

const fetchReviews = async (): Promise<void> => {
  try {
    // reviews.value = await useContentful().reviews.all();
    // Fetch all reviews and group them by subject
    const allReviews = await useContentful().reviews.all();
    reviews.value = allReviews.reduce((acc: Record<string, Review[]>, review: Review) => {
      if (!acc[review.subject]) {
        acc[review.subject] = [];
      }
      acc[review.subject].push(review);
      return acc;
    }, {});
  } catch (e) {
    error.value = e as Error;
  } finally {
    loading.value = false;
  }
};

onMounted(fetchReviews);
</script>

<template>
  <div class="mx-auto px-4 py-8">
    <LoadingComponent v-if="loading" />
    <ErrorComponent v-else-if="error" />
    <div v-else-if="reviews" class="mt-8">
      <div v-for="(reviewGroup, subject) in reviews" :key="subject" class="mb-6">
        <h3 class="text-xl font-semibold mb-2">{{ subject }}</h3>
        <ul class="space-y-2">
          <li v-for="review in reviewGroup" :key="review.id" class="flex items-center justify-between">
            <router-link :to="{ name: 'ReviewDetail', params: { slug: review.slug } }">
              {{ review.source }}
            </router-link>
            <span class="text-base-400">{{ review.rating ? review.stars : '' }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
