<script lang="ts" setup>
import NavbarComponent from "@/components/NavbarComponent.vue";
import FootComponent from "@/components/FootComponent.vue";
import SectionComponent from "@/components/SectionComponent.vue";
import NewsComponent from "@/components/NewsComponent.vue";
import { useContentful } from "@/composables/useContentful";
import { useRoute } from "vue-router";
import type News from "@/models/contentful";

type Locale = "en" | "it";

const route = useRoute();

const locale = (route.query.locale as Locale) ?? (route.query.lang as Locale) ?? "en";

let year = route.params.year as string | undefined;
let month = route.params.month as string | undefined;
let day = route.params.day as string | undefined;
let slug = route.params.slug as string | undefined;

let allNews: News[] = [];

if (year && month && day && slug) {
  allNews = [await useContentful().news.byDatedSlug(year, month, day, slug, locale)];
} else {
  allNews = await useContentful().news.all(100, locale);
}
</script>

<template>
  <NavbarComponent />
  <SectionComponent id="news">
    <NewsComponent v-for="news in allNews" :key="news.id" :news="news" :class="{ 'pb-50': allNews.length > 1 }"
      :full="allNews.length === 1" :locale="locale" />
  </SectionComponent>
  <FootComponent />
</template>

<style lang="css" scoped></style>
