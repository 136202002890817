import News, { Biography, Footer, Review } from "@/models/contentful";
import router from "@/router";
import { usePreferredLanguages } from "@vueuse/core";
import contentful from "@/plugins/contentful";
import type { TypeBiography, TypeFooter, TypeNews } from "@/plugins/contentful";
import type { Entry, EntrySkeletonType } from "contentful";
import type { TypeReview } from "@/plugins/contentful/types/review";

type Locale = "en" | "it";

const languages = usePreferredLanguages();
const preferredLocale = languages.value[0].split("-")[0] as Locale;

const handleNotFound = (contentType: string): never => {
  router.push({ name: "NotFound" });
  throw new Error(`${contentType} not found`);
};

const getEntries = async <T extends EntrySkeletonType, M>(
  contentType: string,
  ModelClass: new (entry: Entry<T>) => M,
  query: Record<string, unknown> = {},
  locale: Locale = preferredLocale
): Promise<M[]> =>
  await contentful
    .getEntries<T>({
      content_type: contentType,
      include: 3,
      locale,
      ...query,
    })
    .then(entries => entries.items.map(entry => new ModelClass(entry)))
    .catch(() => handleNotFound(contentType));

const getEntry = async <T extends EntrySkeletonType, M>(
  contentType: string,
  ModelClass: new (entry: Entry<T>) => M,
  id: string,
  locale: Locale = preferredLocale
): Promise<M> =>
  await contentful
    .getEntry<T>(id, { locale })
    .then(entry => new ModelClass(entry))
    .catch(() => handleNotFound(contentType));

// News specific functions
const getAllNews = (limit: number = 10, locale?: Locale): Promise<News[]> =>
  getEntries<TypeNews, News>(
    "newsPost",
    News,
    {
      order: ["-fields.date"],
      limit,
    },
    locale
  );

const getNewsById = (id: string, locale?: Locale): Promise<News> =>
  getEntry<TypeNews, News>("newsPost", News, id, locale);

const getNewsBySlug = (slug: string, locale?: Locale): Promise<News> =>
  getEntries<TypeNews, News>(
    "newsPost",
    News,
    {
      "fields.slug": slug,
    },
    locale
  ).then(news => news[0]);

const getNewsByDatedSlug = (
  year: string,
  month: string,
  day: string,
  slug: string,
  locale?: Locale
): Promise<News> =>
  getEntries<TypeNews, News>(
    "newsPost",
    News,
    {
      "fields.slug": slug,
      "fields.date": `${year}-${month}-${day}`,
    },
    locale
  ).then(news => news[0]);

// Footer specific functions
const getFooter = (locale?: Locale): Promise<Footer> =>
  getEntry<TypeFooter, Footer>("footer", Footer, "20XLclqKGaXecbCqHdCwEv", locale);

// Biography specific functions
const getBio = (id: string, locale?: Locale): Promise<Biography> =>
  getEntry<TypeBiography, Biography>("biography", Biography, id, locale);

const getShortBio = (locale?: Locale): Promise<Biography> =>
  getBio("2pwp5qEzG4pM4vMUknkfzz", locale);

const getFullBio = (locale?: Locale): Promise<Biography> =>
  getBio("33YNYH77DX6sUMyOqGl5ek", locale);

// Reviews specific functions
const getAllReviews = (locale?: Locale): Promise<Review[]> =>
  getEntries<TypeReview, Review>(
    "review",
    Review,
    {
      order: ["-fields.publishedDate"],
    },
    locale
  );

const getReviewById = (id: string, locale?: Locale): Promise<Review> =>
  getEntry<TypeReview, Review>("review", Review, id, locale);

const getReviewBySlug = async (slug: string): Promise<Review | null> => {
  // Extract the Contentful ID from the slug (it's the last part after the last underscore)
  const id = slug.split('_').pop();
  if (!id) return null;

  // Use the ID to fetch the review directly
  return await contentful.getEntry<TypeReview>(id)
    .then(entry => new Review(entry))
    .catch(() => null);
};

export const useContentful = (): {
  getEntries: typeof getEntries;
  getEntry: typeof getEntry;
  news: {
    all: typeof getAllNews;
    byId: typeof getNewsById;
    bySlug: typeof getNewsBySlug;
    byDatedSlug: typeof getNewsByDatedSlug;
  };
  footer: typeof getFooter;
  bio: {
    short: typeof getShortBio;
    full: typeof getFullBio;
  };
  reviews: {
    all: typeof getAllReviews;
    byId: typeof getReviewById;
    bySlug: typeof getReviewBySlug;
  };
} => ({
  // Generic functions
  getEntries,
  getEntry,

  // News functions
  news: {
    all: getAllNews,
    byId: getNewsById,
    bySlug: getNewsBySlug,
    byDatedSlug: getNewsByDatedSlug,
  },

  // Footer functions
  footer: getFooter,

  // Biography functions
  bio: {
    short: getShortBio,
    full: getFullBio,
  },

  // Reviews functions
  reviews: {
    all: getAllReviews,
    byId: getReviewById,
    bySlug: getReviewBySlug,
  },
});
