<script lang="ts" setup>
import { computed, ref } from "vue";
import { Bars3Icon, XMarkIcon } from "@heroicons/vue/24/outline";
// import RotelloneIcon from "@/components/RotelloneIcon.vue";
import ImageResponsive from "./ImageResponsive.vue";

const navigation = ref([
  { name: "Home", route: "home" },
  { name: "Music", route: "music" },
  { name: "Shows", route: "shows" },
  { name: "News", route: "news" },
  { name: "About", route: "about" },
  { name: "Shop", route: "shop" },
]);

const width = ref(window.innerWidth);
window.addEventListener("resize", () => {
  width.value = window.innerWidth;
});

const showNavigationModal = ref(false);

// Split links into two groups for large screens
const leftLinks = computed(() => navigation.value.slice(0, Math.ceil(navigation.value.length / 2)));
const rightLinks = computed(() => navigation.value.slice(Math.ceil(navigation.value.length / 2)));
</script>

<template>
  <div class="bg-black relative z-10">
    <div class="mx-auto max-w-7xl px-2">
      <div class="relative items-center justify-between">
        <div class="hidden sm:flex flex-wrap lg:flex-nowrap w-full justify-center items-center mx-auto pt-4 sm:p-4">
          <div class="block space-x-6 w-1/2 lg:w-2/5 order-2 lg:order-1 shrink lg:grow text-right md:pr-5">
            <RouterLink
              v-for="link in leftLinks"
              :key="link.route"
              :to="{ name: link.route }"
              class="uppercase font-bold px-1 py-2 text-sm"
            >
              {{ link.name }}
            </RouterLink>
          </div>
          <div class="block space-x-6 w-full lg:w-1/5 order-1 lg:order-2 shrink lg:grow md:pb-4">
            <RouterLink to="home" aria-label="Navigate to Home">
              <ImageResponsive local="logo_arancione" alt="Disease Illusion Logo" class="h-18 m-auto" />
            </RouterLink>
          </div>
          <div class="block space-x-6 w-1/2 lg:w-2/5 order-3 lg:order-3 shrink lg:grow text-left md:pl-5">
            <RouterLink
              v-for="link in rightLinks"
              :key="link.route"
              :to="{ name: link.route }"
              class="uppercase font-bold px-1 py-2 text-sm"
            >
              {{ link.name }}
            </RouterLink>
          </div>
        </div>
        <div class="block sm:hidden">
          <Bars3Icon class="fill-current size-10" @click="showNavigationModal = true" />
        </div>
      </div>
    </div>
  </div>

  <div v-if="showNavigationModal" class="fixed inset-0 bg-black z-50">
    <div class="flex flex-col h-full justify-center items-center gap-12">
      <RouterLink
        v-for="link in navigation"
        :key="link.route"
        :to="{ name: link.route }"
        class="text-white uppercase font-bold px-3 py-2 text-2xl"
        @click="showNavigationModal = false"
      >
        {{ link.name }}
      </RouterLink>
      <XMarkIcon class="mt-10 size-10 text-white" @click="showNavigationModal = false" />
    </div>
  </div>
</template>

<style lang="css" scoped></style>
